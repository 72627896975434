<template>
  <alert
    v-if="displayAlert && activeRowId === rowData?.data?.id"
    :type="alertType"
    rounded-corners
    centered-text
    :alert-text="alertText"/>
</template>

<script setup>
import { computed } from 'vue';
import Alert from '@/components/page-elements/Alert.vue';

const props = defineProps({
  displayAlert: {
    type: Boolean,
    required: false,
    default: false,
  },
  activeAction: {
    type: String,
    required: false,
    default: '',
  },
  activeRowId: {
    type: Number,
    required: false,
    default: null,
  },
  rowData: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const alertText = computed(() => {
  switch (props.activeAction) {
    case 'copy':
      return 'Row has been copied.';
    case 'delete':
      return 'Row has been deleted.';
    case 'edit':
      return 'Row has been edited.';
    case 'unpublish':
      return 'Row has been unpublished.';
    case 'disable':
      return 'Row has been disabled.';
    case 'activate':
      return 'Row has been activated.';
    case 'manage list':
      return 'The list in this row has been updated.';
    default:
      return '';
  }
});

const alertType = computed(() => {
  switch (props.activeAction) {
    case 'copy':
      return 'success';
    case 'delete':
      return 'danger';
    case 'danger':
      return 'delete';
    case 'edit':
      return 'success';
    case 'unpublish':
      return 'danger';
    case 'disable':
      return 'warning';
    case 'activate':
      return 'success';
    case 'manage list':
      return 'warning';
    default:
      return '';
  }
});
</script>

<style lang="scss" scoped>
  :deep(.martech-alert) {
    position: absolute;
    top: -5px;
    left: -25%;
    right: 0;
    width: 100%;
    display: block;
    margin: auto;
    z-index: 5;
  }
</style>