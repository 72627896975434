<template>
  <div class="display-tables">
    <div class="decks-list">
      <div class="header">
        <div class="group">
          <span>
            <h1 class="martech-sub-heading martech-barlow martech-text-uppercase">
              {{ header }} Decks
            </h1>
            <h2 v-if="props.affiliate" class="martech-labels">
              View Creator Profile: <base-link
                :link-url="`/author/${ props.userFullName?.replaceAll(' ', '-') }/decks`"
                class="martech-labels martech-blue"
                tabindex="-1">
                  {{ props.userFullName }}
                </base-link>
            </h2>
          </span>
          <base-button
            btn-size="small"
            @clicked="addDeck">
            Add New Deck
          </base-button>
        </div>
      </div> 
      <div v-if="originalCount === 0" class="no-decks">
        <h1 class="martech-sub-heading martech-barlow martech-text-uppercase">
          We didn't find any decks for you!
        </h1>
        <h2 v-if="props.affiliate" class="martech-labels">
          Check your filters, or get started by clicking Add New Deck to add a deck to your profile.
        </h2>
      </div>
      <div v-else :class="{ 'no-decks-filter': count === 0 }">
        <div class="search-group">
          <p class="martech-text-md">
            <template v-if="count === 0">
              0 Decks Found
            </template>
            <template v-else-if="count === 1">
              1 Deck
            </template>
            <template v-else>
              {{ count }} Decks
            </template>
          </p>
          <table-header
            :table-api="tableApi"
            class="table-header"/>
        </div>
        <div class="table">
          <tcg-table
            class="set-table"
            :headers="headers"
            v-bind="tableProps"
            @api="tableApi = $event"
            @rowsPerPageChanged="onRowsPerPageChanged"
            @pageChanged="onPageChanged"
            @searchChanged="onSearchChanged"
            @sortChanged="onSortChanged">
            <template #tableHeader>
              <span/>
            </template>

            <template #toolbar>
              <span/>
            </template>

            <template v-if="!props.affiliate" #[`body.id.content`]="{ row }">
              {{ row.data.id }}
            </template>

            <template v-if="!props.affiliate"  #[`body.name.content`]="{ row }">
              {{ row.data.name }}
            </template>

            <template v-if="!props.affiliate"  #[`body.player.content`]="{ row }">
              {{ row.data.player }}
            </template>

            <template v-if="!props.affiliate"  #[`body.format.content`]="{ row }">
              {{ row.data.format }}
            </template>

            <template v-if="!props.affiliate" #[`body.deckSource.content`]="{ row }">
              {{ row.data.deckSource }}
            </template>

            <template v-if="props.affiliate"  #[`body.name.content`]="{ row }">
              {{ row.data.name }}
            </template>

            <template v-if="props.affiliate"  #[`body.game.content`]="{ row }">
              {{ verts.displayName(row.data.game) }}
            </template>

            <template v-if="props.affiliate"  #[`body.format.content`]="{ row }">
              {{ row.data.format }}
            </template>

            <template v-if="props.affiliate" #[`body.created.content`]="{ row }">
              {{ row.data.created }}
            </template>

            <template #[`body.actions.content`]="{ row }">
              <div class="actions-group">
                <action-alert
                  :active-action="selectedAction"
                  :display-alert="displayActiveAlert"
                  :row-data="row"
                  :active-row-id="activeRowId"/>
                <action-buttons
                  :row-data="row"
                  @view="previewDeck(row)"
                  @edit="editDeck(row)"
                  @delete="deleteDeck(row)"/>
              </div>
            </template>
          </tcg-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { get, set, useDebounceFn } from '@vueuse/core';
import TableHeader from '@/components/table/TableHeader.vue';
import ActionButtons from '@/components/table/ActionButtons.vue';
import ActionAlert from '@/components/table/TableActionAlert.vue';
import BaseButton from '@/components/elements/BaseButton.vue';
import dates from '@/lib/dates';
import verts from '@/lib/verticals';
import StringHelper from '@/lib/strings';
import TcgTable from '@tcgplayer/design-system/src/components/Table/Table.vue';
import useDeckNameToUrl from '@/use/deck/useNameToUrl';
import BaseLink from '@/components/elements/BaseLink.vue';

const props = defineProps({
  affiliate: {
    type: Boolean,
    required: true,
  },
  header: {
    type: String,
    required: true,
  },
  game: {
    type: String,
    required: false,
  },
  userName: {
    type: String,
    required: false,
  },
  userFullName: {
    type: String,
    required: false,
  },
  router: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  api: {
    type: Object,
    default: () => ({
      getDecks: () => Promise.resolve({}),
    }),
    validator(val, props) {
      let valid = true;
      Object.keys(val).forEach((key) => {
        if (typeof val[key] !== 'function') {
          valid = false;
        }
      });
      return valid;
    }
  },
});

const decks = ref([]);
const loading = ref(false);
const count = ref();
const rows = ref(100);
const page = ref(1);
const search = ref('');
const sortField = ref('');
const sortDir = ref('');
const displayActiveAlert = ref(false);
const activeRowId = ref();
const selectedAction = ref();
const originalCount = ref();

const getDecks = () => {
  set(loading, true);
  props.api.getDecks(props.game, get(search), (get(page) - 1) * get(rows), get(rows), get(sortField), get(sortDir))
    .then((response) => {
      const result = response?.data?.result || [];
      for (let i = 0; i < result.length; i++) {
        result[i].name = result[i].deck.name;
        result[i].game = result[i].deck.game;
        result[i].player = result[i].deck.playerName || result[i].deck.playerHandle;
        result[i].format = StringHelper.titleCase(result[i].deck.format);
        result[i].created = dates.format(result[i].deck.created);
        result[i].actionButtonTypes = [ 'view', 'edit', ];
        if (!props.affiliate) {
          result[i].actionButtonTypes.push('delete');
        }

        if (result[i].deck.deckSource === 'dashboard') {
          result[i].deckSource = 'Dashboard';
        } else if (result[i].deck.deckSource === 'Dominaria Ingestion') {
          result[i].deckSource = 'Dominaria';
        } else if (result[i].deck.deckSource === 'affiliate') {
          result[i].deckSource = 'Affiliate';
        }
      }

      set(decks, result);
      set(count, response?.data?.total || 0);

      if (get(originalCount) === undefined) {
        set(originalCount, count.value);
      }

      set(loading, false);
    })
    .catch((err) => {
      set(count, 0);
      set(decks, []);
    });
};

getDecks();

const deleteDeck = (row) => {
  set(displayActiveAlert, true);
  set(selectedAction, 'unpublish');
  set(activeRowId, row?.data?.id);

  props.api.deleteDeck(row?.data?.id)
    .then((response) => {
      getDecks();
      setTimeout(() => {
        set(displayActiveAlert, false);
        set(selectedAction, '');
        set(activeRowId, null);
      }, 5000);
    });
  return true;
};

const addDeck = () => {
 if (props.affiliate) {
  props.router.push({ name: 'submitDeck' });
 } else {
  props.router.push({ path: `/deck-entry/${props.game.replaceAll(' ', '-')}` });
 }
};

const editDeck = (row) => {
  if (props.affiliate) {
    props.router.push({ name: 'submitDeck', query: { deckId: row?.data?.id, copy: 1 } });
  } else if (row?.data?.deckSource.toLowerCase() === 'affiliate') {
    props.router.push({ path: `/deck-entry/${row?.data?.game.replaceAll(' ', '-')}`, query: { deckId: row?.data?.id, copy: 1, affiliate: true } });
  } else {
    props.router.push({ path: `/deck-entry/${row?.data?.game.replaceAll(' ', '-')}`, query: { deckId: row?.data?.id, copy: 1 } });
  }
};

const previewDeck = (row) => {
  if (props.affiliate) {
    const { deckUrl } = useDeckNameToUrl(row?.data?.name || '');
    props.router.push({ path: verts.getVerticalLinkPath(row?.data?.game, `/deck/${deckUrl.value}/${row?.data?.id}`) });
  } else {
    props.router.push({ path: `/deck-preview/${row?.data?.game}/${row?.data?.id}` });
  }
};

const onRowsPerPageChanged = (n) => {
  set(rows, n);
  getDecks();
};

const onPageChanged = (n) => {
  set(page, n);
  getDecks();
};

const onSortChanged = (e) => {
  set(sortField, e.header.sortOrder ? e.header.value : '');
  set(sortDir, e.header.sortOrder || '');
  getDecks();
};

const onSearchChanged = useDebounceFn((text) => {
  set(search, text);
  set(page, 1);
  getDecks();
}, 1000);

watch(
  () => props.game,
  () => {
    set(search, '');
    set(page, 1);
    getDecks();
  }
);

const headers = computed(() => {
  if (props.affiliate) {
    return [
      {
        text: 'Name',
        value: 'name',
        sort: 'alpha',
        sortable: true,
        width: '45%',
      },
      {
        text: 'Game',
        value: 'game',
        sortable: true,
        width: '15%',
      },
      {
        text: 'Format',
        value: 'format',
        sortable: true,
        width: '15%',
      },
      {
        text: 'Created',
        value: 'created',
        sortable: true,
        width: '15%',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '10%',
      }
    ];
  }
  return [
      {
        text: 'ID',
        value: 'id',
        sortable: true,
        width: '5%',
      },
      {
        text: 'Name',
        value: 'name',
        sort: 'alpha',
        sortable: true,
        width: '30%',
      },
      {
        text: 'Player',
        value: 'player',
        sort: 'player',
        sortable: true,
        width: '15%',
      },
      {
        text: 'Format',
        value: 'format',
        sortable: true,
      },
      {
        text: 'Source',
        value: 'deckSource',
        sortable: false,
        width: '120px',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '300px',
      }
    ];
});

const tableProps = computed(() => {
  const tabProps = {
    data: get(decks),
    selectable: false,
    searchable: true,
    pagination: true,
    multisort: false,
    sortable: false,
    serverSideMode: true,
    totalRows: get(count),
    rowsPerPage: get(rows),
    rowsPerPageOptions: [ 10, 25, 50, 75, 100 ],
  };

  return tabProps;
});
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: $martech-spacer-3 0 $martech-spacer-4 0;

    @include breakpoint(768) {
      flex-direction: row;
      margin: 0 0 $martech-spacer-3 0;
    }

  h1 {
    padding: $martech-spacer-1 $martech-spacer-3 0 0;
  }

  .group {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;

    @include breakpoint(768) {
      flex-direction: row;
      justify-content: flex-start;
    }

    :deep(.martech-button) {
      margin-left: $martech-spacer-4;
    }
  }
}

.no-decks {
  text-align: center;
  padding: $martech-spacer-4 0;
}

.search-group {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: $martech-spacer-4;
    align-items: center;

    p {
      text-transform: uppercase;
      font-weight: $martech-weight-semibold;
    }
  }

  .actions-group {
    position: relative;
  }

:deep(.tcg-table-toolbar) {
  display: none;
}

:deep(.tcg-table) {
  &.is-entire-row-selectable {
    .tcg-table__table tbody tr:hover {
      cursor: default;
    }
  }
}

.no-decks-filter {
  :deep(.tcg-table) {
    display: none;
  }
}
</style>
