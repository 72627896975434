<template>
  <div class="action-buttons">
    <template v-for="(action, index) in rowData.data.actionButtonTypes" :key="index">
      <button @click="actionClicked(action)">
        <img :src="tableIcons(action)" :title="action" :alt="action" :class="{'check' : action === 'activate'}">
      </button>
    </template>
    <tcg-modal v-model="showModalPrompt">
      <tcg-modal-title>
        {{ modalTitle }}
      </tcg-modal-title>
      <tcg-modal-content>
        {{ modalMessage }}
      </tcg-modal-content>
      <tcg-modal-actions>
        <tcg-button @click="showModalPrompt = false">
          Cancel
        </tcg-button>
        <tcg-button
          @click="modalAction">
          Confirm
        </tcg-button>
      </tcg-modal-actions>
    </tcg-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { set } from '@vueuse/core';
import TcgButton from '@tcgplayer/design-system/src/components/Button/Button.vue';
import TcgModal from '@tcgplayer/design-system/src/components/Modal/Modal.vue';
import TcgModalActions from '@tcgplayer/design-system/src/components/Modal/ModalActions.vue';
import TcgModalContent from '@tcgplayer/design-system/src/components/Modal/ModalContent.vue';
import TcgModalTitle from '@tcgplayer/design-system/src/components/Modal/ModalTitle.vue';

const props = defineProps({
  displayAlert: {
    type: Boolean,
    required: false,
    default: false,
  },
  activeAlertType: {
    type: String,
    required: false,
    default: '',
  },
  rowData: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const emit = defineEmits([ 'copy', 'delete', 'view', 'edit', 'unpublish', 'disable', 'activate', 'manage-list', ]);
const showModalPrompt = ref(false);
const modalTitle = ref();
const modalMessage = ref();
const modalAction = ref();

const tableIcons = (type) => {
  switch (type) {
    case 'copy':
      return 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/copy.svg';
    case 'delete':
      return 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/dashboard/delete.svg';
    case 'view':
      return 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/dashboard/view.svg';
    case 'edit':
      return 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/dashboard/edit.svg';
    case 'unpublish':
      return 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/dashboard/unpublish.svg';
    case 'disable':
      return 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/dashboard/disable-2.svg';
    case 'activate':
      return 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/dashboard/activate-2.svg';
    case 'manage list':
      return 'https://tcgplayer-cdn.tcgplayer.com/infinite/images/dashboard/check-list-2.svg';
    default:
      return '';
  }
};

const closeModal = (emitName) => {
  emit(emitName);
  set(showModalPrompt, false);
};

const unpublishRow = () => {
  set(showModalPrompt, true);
  set(modalTitle, 'Unpublish');
  set(modalMessage, `Are you sure you want to unpublish ID: ${props.rowData?.data?.id} - ${props.rowData?.data?.title}`);
  modalAction.value = () => closeModal('unpublish');
};

const deleteRow = () => {
  set(showModalPrompt, true);
  set(modalTitle, 'Unpublish');

  const label = props.rowData?.data?.title || props.rowData?.data?.deck?.name;
  set(modalMessage, `Are you sure you want to delete ID: ${props.rowData?.data?.id} - ${label}`);
  modalAction.value = () => closeModal('delete');
};

const actionClicked = (type) => {
  switch (type) {
    case 'copy':
      return emit('copy');
    case 'delete':
      return deleteRow();
    case 'view':
      return emit('view');
    case 'edit':
      return emit('edit');
    case 'unpublish':
      return unpublishRow();
    case 'disable':
      return emit('disable');
    case 'activate':
      return emit('activate');
    case 'manage list':
      return emit('manage-list');
    default:
      return '';
  }
};
</script>

<style lang="scss" scoped>
  .action-buttons {
    display: flex;
    align-items: center;
    position: relative;

    img {
      height: 18px;

      &.check {
        height: 14px;
      }
    }

    button {
      padding: 0 12px;
      background-color: transparent;
    }

    :deep(.martech-modal) {
      left: -25vw;
    }
  }
</style>
