<template>
    <div class="full-art-article-grid" :data-testid="componentID()">
        <div class="header">
          <h2 class="martech-sub-heading martech-semi-condensed martech-text-uppercase martech-text-semibold">
            {{ title }}
          </h2>
        </div>
        <div class="full-art-article-grid--grid">
          <!--placeholder components-->
          <div v-if="loading" class="grids placeholder-wrapper">
            loading
            <div v-if="hasLargeFeature" class="grid-large">
              <full-art-placeholder :include-text="false"/>
            </div>
            <div class="grid-small">
              <div v-for="p in placeholderCount" :key="p" class="item">
                <full-art-placeholder :include-text="false"/>
              </div>
            </div>
          </div>
          <!--grid components-->
          <div v-show="!loading" class="grids">
            <template v-if="hasLargeFeature">
              <div v-for="article in articles.slice(0,1)" :key="article.uuid" class="grid-large">
                <large-image-card :article-data="article" :show-vertical-link="true" @click="handleEventClick"/>
              </div>
            </template>
            <div class="grid-small">
              <div v-for="article in formattedArticles" :key="article.uuid" class="item">
                <large-image-card :article-data="article" :show-vertical-link="true" @click="handleEventClick"/>
              </div>
            </div>
          </div>
          <base-button
            v-if="buttonText"
            @click="emit('clicked')"
            class="see-more-button"
            :aria-label="buttonText"
            btn-style="martech-black">
            {{ buttonText }}
        </base-button>
        </div>
    </div>
  </template>

  <script setup>
  import { computed } from 'vue';
  import useComponentId from '@/use/useComponentId';
  import useDeviceType from '@/use/deviceType';
  import LargeImageCard from '@/components/cards/image/LargeSquareFull.vue';
  import BaseButton from '@/components/elements/BaseButton.vue';
  import FullArtPlaceholder from '@/components/placeholder/FullArt.vue';

const emit = defineEmits(['clicked']);

const props = defineProps({
    articles: {
        type: Object,
        required: true,
    },
    buttonText: {
        type: String,
        required: false,
        default: ''
    },
    title: {
        type: String,
        required: false,
        default: 'Latest Articles'
    },
    rows: {
      type: Number,
      required: true,
      default: 5,
    },
    hasLargeFeature: {
      type: Boolean,
      required: false,
      default: true,
    },
    exclude: {
      type: Array,
      required: false,
      default: () => [],
    },
    slice: {
      type: Number,
      required: false,
      default: null,
      note: 'Used to pass the exact number of items you want if you need to exclude an id from the grid.',
    },
    placeholderCount: {
      type: Number,
      required: false,
      default: 6,
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    }
  });

  const { isMobile, isDesktop } = useDeviceType();
  const { componentID } = useComponentId();


  const formattedArticles = computed(() => {
    return (props.hasLargeFeature ? props.articles?.slice?.(1, props.rows) : props.articles?.slice?.(0, props.rows))
  });
  </script>

  <style lang="scss" scoped>
  .full-art-article-grid {
    position: relative;
    width: 100%;
    overflow: hidden;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: $martech-spacer-2;
    }

    .grids {
      display: flex;
      list-style: none;
      flex-direction: column;

      @include breakpoint(1024) {
        flex-direction: row;
      }

      &.placeholder-wrapper {
        .grid-large {
          :deep(.placeholder) {
            height: 209px;

            @include breakpoint(1024) {
              height: 615px;
            }
          }
        }

        .grid-small {
          .item {
            height: 100%;

            @include breakpoint(1024) {
              height: 192px;
            }

            :deep(.placeholder) {
              height: 192px;
            }
          }
        }
      }

      .grid-large {
        padding-bottom: $martech-spacer-3;

        @include breakpoint(1024) {
          padding-bottom: 0;
          padding-right: $martech-spacer-3;
          width: 40%;
          align-self: stretch;


          :deep(.grid) {
            display: flex;
            align-items: stretch;
            height: 100%;

            h3 {
              @include breakpoint(1024) {
                font-size: $martech-type-32;
                line-height: 2.125rem;
              }
            }

            .background-overlay {
              height: 300px;
            }

            :deep(.new-tag) {
              width: 5rem;
              height: 2rem;
            }
          }
        }

        @include breakpoint(1500) {
          width: 50%;
        }
      }

      .grid-small {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include breakpoint(1024) {
          width: 60%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: $martech-spacer-3;

          &.no-feature {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: $martech-spacer-3;
            width: 100%;
            height: auto;
          }
        }

        @include breakpoint(1500) {
          width: 50%;
        }

        .item {
          width: 100%;
          padding-bottom: $martech-spacer-3;
          height: 258px;

          @include breakpoint(1024) {
            padding-bottom: 0;
          }
        }
      }
    }

    .see-more-button {
      display: flex;
      justify-content: center;
      margin-top: $martech-spacer-4;
    }
  }
  </style>
