<template>
  <base-link :link-url="linkURL || data.linkURL" :data-testid="componentID()">
    <div class="series-card shadow">
      <div class="series-card__image">
        <img :src="data.iconImageURL" alt="" />
      </div>
      <div class="series-card__content">
        <h2 class="martech-semi-condensed martech-text-black martech-body martech-one-line">{{ data.name }}</h2>
        <p class="martech-text-sm martech-two-lines">{{ data.description.replaceAll(stripTags, '') }}</p>
      </div>
    </div>
  </base-link>
</template>

<script setup>
import useComponentId from '@/use/useComponentId';
import BaseLink from '@/components/elements/BaseLink.vue';

defineProps({
  data: {
    type: Object,
    required: true,
  },
  linkURL: {
    type: String,
    required: false,
    default: 'article',
  }
});

const { componentID } = useComponentId();

const stripTags =  /<\/?[^>]+>/g;
</script>

<style lang="scss" scoped>
.series-card {
  display: flex;
  padding: $martech-spacer-2;
  border-radius: $martech-radius-medium;
  min-height: 80px;

  &__image {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 60px;
      height: 100%;
    }
  }

  &__content {
    margin-left: 10px;
    color: $martech-text-primary;

    h2 {
      text-transform: uppercase;
    }
  }
}
</style>