<template>
    <img :src="logo" alt="Team CFB Ultimate Guard" class="ultimate-guard-logo">
</template>

<script setup>
import { computed, } from 'vue';

const props = defineProps({
  blackLogo: {
    type: Boolean,
    default: false,
  }
})

const wLogo = 'https://mktg-assets.tcgplayer.com/content/channel-fireball/site-images/team-cfb-ultimate-guard.svg';
const bLogo = 'https://mktg-assets.tcgplayer.com/content/channel-fireball/site-images/team-cfb-ultimate-guard-black.svg';

const logo = computed(() => props.blackLogo ? bLogo : wLogo);
</script>

<style lang="scss" scoped>
.ultimate-guard-logo {
    height: 30px;
}
</style>