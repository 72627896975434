<template>
  <!--eslint-disable-next-line-->
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" id="search-icon"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M31.78,31.16l-.62.62a.74.74,0,0,1-1.06,0L22,23.72a.75.75,0,0,1-.22-.53v-.64a13.24,13.24,0,1,1,.72-.73h.65a.71.71,0,0,1,.53.22l8.06,8.06A.74.74,0,0,1,31.78,31.16ZM24,13A11,11,0,1,0,13,24,11,11,0,0,0,24,13Z" fill="currentColor"/></g></svg>
</template>

<script>
export default {
  name: 'search-icon',
};
</script>

<style lang="scss" scoped>
#search-icon {
  width: 18px;
}
</style>