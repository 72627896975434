<template>
  <base-link :link-url="linkURL || data.linkURL" :data-testid="componentID()"
    :class="{ 'no-cursor': data.text === 'Coming Soon' }">
    <div class="spotlight-button" :style="[{ 'background-image': 'url(' + data.imageURL + ')' }, {height : cardHeight}]">
      <div class="header overlay">
        <img v-if="data.overlayImageURL && !showTitle" :src="data.overlayImageURL" :alt="data.name" />
        <img v-if="data.iconImageURL && showIcon" :src="data.iconImageURL" :alt="data.name" class="icon"/>
        <h2 v-if="showTitle" class="martech-semi-condensed">
          {{ data.name }}
        </h2>
      </div>
      <div class="footer" v-if="showFooter">
        <p class="martech-text-md">
          {{ footerText(data.name) }}
        </p>
        <chevron direction="right" />
      </div>
    </div>
  </base-link>
</template>

<script setup>
import useComponentId from '@/use/useComponentId';
import Chevron from '@/components/iconography/Chevron.vue';
import BaseLink from '@/components/elements/BaseLink.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  showTitle: {
    type: Boolean,
    required: false,
  },
  contentType: {
    type: String,
    required: false,
    default: 'article',
  },
  linkURL: {
    type: String,
    required: false,
    default: 'article',
  },
  showFooter: {
    type: Boolean,
    required: false,
    default: true,
  },
  showIcon: {
    type: Boolean,
    required: false,
    default: false,
  },
  cardHeight: {
    type: String,
    required: false,
    default: '100%',
  },
});

const { componentID } = useComponentId();

const footerText = (name) => {
  if (props.data?.text === 'Coming Soon') {
    return 'Coming Soon';
  }

  if (props.contentType === 'decks') return `Latest ${name} Decks`;

  return `See the latest in ${name}`;
};

</script>

<style lang="scss" scoped>
a {
  &:focus-visible {
    .spotlight-button {
      outline: 2px dashed $martech-blue-120;
    }
  }

  &.no-cursor {
    cursor: default;
  }
}

.spotlight-button {
  border-radius: $martech-radius-medium;
  width: 100%;
  background-size: cover;
  background-position: 25% 25%;
  position: relative;
  background-color: $martech-black;
  overflow: hidden;

  .header {
    text-shadow: 1px 1px 2px $martech-black;
    text-transform: uppercase;
    color: $martech-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    z-index: 2;
    min-height: 125px;

    h2 {
      letter-spacing: 2px;
      font-size: $martech-type-20;
      color: $martech-white;
      text-align: center;
      padding: 0 $martech-spacer-3;
      position: relative;
      font-weight: $martech-weight-bold;
    }
  }

  p {
    color: $martech-white;
  }

  img {
    width: 225px;
  }

  .icon {
    height: 60px;
    margin-bottom: $martech-spacer-2;
  }

  .overlay {
    border-image: fill 0 linear-gradient(rgba(4, 4, 4, 0.1), $martech-black);
  }

  .footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $martech-spacer-2 $martech-spacer-3;
    background-color: rgba(0, 0, 0, 0.9);
    border-top: 1px solid $martech-gray-110;
    width: 100%;
    min-height: 57px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 5;

    @include breakpoint(1024) {
      min-height: 40px;
      padding: $martech-spacer-3;
    }

    @include breakpoint(1200) {
      min-height: 73px;
    }

    @include breakpoint(1500) {
      min-height: 40px;
    }

    p {
      color: $martech-white;
      font-weight: $martech-weight-light;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      @include breakpoint(768) {
        bottom: 15px;
      }
    }

    :deep(.martech-chevron) {
      width: 12px;
      margin-left: $martech-spacer-2;
      color: $martech-white;

      @include breakpoint(768) {
        width: 15px;
      }
    }
  }
}
</style>