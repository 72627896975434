<template>
  <div
    v-if="isActive"
    class="site-alert notification is-warning"
  >
    <strong v-if="alertTitle">{{ alertTitle }}</strong>
    {{ alertMessage }}
    <a
      v-if="alertUrl"
      :href="alertUrl"
      aria-label="Read more about this alert"
      @click.passive="logAnalytics(alertColor)"
      @keypress.enter.space="logAnalytics(alertColor)"
    >{{ alertLink }}</a>
  </div>
</template>

<script>
import amplitudeEvent from '@tcgplayer/amplitude';

export default {
  name: 'site-alert-notification',
  introduction: 'A site alert message bar',
  description: 'A global notification for importing/pressing messages across the platform',
  token: `<site-alert-notification
      alert-title="Winter Weather Alert."
      alert-message="Inclement weather is affecting delivery of many packages from TCGplayer direct."
      alert-url="https://help.tcgplayer.com"
      alert-link="Learn More"
      :is-active="false"
/>`,
  props: {
    alertTitle: {
      type: String,
      required: false,
      default: '',
      note: 'The bolded alert title',
    },
    alertMessage: {
      type: String,
      required: true,
      default: '',
      note: 'The message displayed in the alert',
    },
    alertLink: {
      type: String,
      required: false,
      default: '',
      note: 'A link at the end of the alert',
    },
    alertUrl: {
      type: String,
      required: false,
      default: '',
      note: 'The URL for the alert link',
    },
    alertColor: {
      type: String,
      required: false,
      default: 'White',
      note: 'The color of the alert for analytics purposes',
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Is the alert currently active?',
    },
  },
  methods: {
    logAnalytics() {
      amplitudeEvent('general', 'alertClick', {
        alertURL: this.alertUrl,
        alertTitle: this.alertTitle,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.site-alert {
  padding: 0.5rem 1.5rem;
  font-weight: 500;
  font-size: 0.875rem;
  letter-spacing: -0.011em;

  &.notification {
    margin-bottom: 0;
    border-radius: 0;
  }
}
</style>
