<template>
  <div class="table-header">
    <tcg-input-text
      v-model="searchTerm"
      size="sm"
      input-style="alternate"
      placeholder="Search"
      label="Search table"
      hide-label>
      <template #leftIcon>
        <search-icon/>
      </template>
    </tcg-input-text>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import SearchIcon from '@/components/iconography/SearchIcon.vue';
import TcgInputText from '@tcgplayer/design-system/src/components/InputText/InputText.vue';

const props = defineProps({
  tableApi: {
    type: Object,
    required: false,
    default: undefined,
  },
});

// Search
const searchTerm = ref();
watch(
  () => searchTerm.value,
  (newVal) => {
    props.tableApi.changeSearchTerm(newVal);
  }
);
</script>

  <style lang="scss" scoped>
  .table-header {
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    width: 50%;

    :deep(.tcg-input-text) {
      width: 100%;

      input {
        background-color: $martech-white;
      }
    }
  }
  </style>
