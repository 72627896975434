<template>
  <div class="season-pagination">
    <span v-if="isMobile">{{ season }}</span>

    <div class="group">
      <button @click="previousSeason" :disabled="hasPreviousSeason"><chevron direction="left"/> Previous Season</button>
      <span v-if="isDesktop" class="martech-text-semibold martech-text-md">{{ season }}</span>
      <button @click="nextSeason" :disabled="hasNextSeason">Next Season <chevron direction="right"/></button>
    </div>
  </div>
</template>

<script setup>
import useDeviceType from '@/use/deviceType';
import Chevron from '@/components/iconography/Chevron.vue';

const props = defineProps({
  season: {
    type: Object,
    required: false,
  },
  hasPreviousSeason: {
    type: Boolean,
    required: false,
  },
  hasNextSeason: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits([ 'next', 'back' ]);
const {isMobile, isDesktop} = useDeviceType();

const nextSeason = () => {
  emit('next');
};

const previousSeason = () => {
  emit('back');
};
</script>

<style lang="scss" scoped>
.season-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0;
  color: $martech-white;
  width: 100%;

  .group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: $martech-spacer-2;

    @include breakpoint(1024) {
      margin-top: 0;
    }
  }

  button {
    border: none;
    background-color: transparent;
    color: $martech-white;
    font-size: $martech-type-14;
    font-weight: $martech-weight-semibold;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    svg {
      height: auto;
      width: 13px;

      &:first-of-type {
         margin-right: $martech-spacer-2;
      }

      &:last-of-type {
         margin-left: $martech-spacer-2;
      }
    }
  }
}
</style>